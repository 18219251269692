import { Controller } from '@hotwired/stimulus'
import { html, render } from 'lit-html'
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js'

export default class extends Controller {
  static values = { json: Object }
  static targets = ['form', 'container', 'phoneField']

  initialize() {
    this.containerTarget.innerHTML = ''
  }

  update() {
    const formData = new FormData(this.formTarget)
    const baseKey = 'lead_capture_form[lead_capture_form_fields_attributes]'
    const fieldIndices = Array.from(
      new Set(
        Array.from(formData.keys())
          .filter((key) => key.startsWith(baseKey))
          .map((key) => key.match(/-?\d+/g)[0])
          .filter((index) => index),
      ),
    )

    const fieldsOrdered = fieldIndices.reduce((acc, index) => {
      const formKey = (name) => `${baseKey}[${index}][${name}]`
      const order = Number(formData.get(formKey('order')))
      const key = formData.get(formKey('key'))
      const label = formData.get(formKey('label'))
      const type = formData.get(formKey('type'))
      const required = key === 'name' || formData.getAll(formKey('required')).includes('1')

      const destroyed = formData.getAll(formKey('_destroy')).includes('1')

      if (!destroyed) {
        acc[order] = { key, label, type, required }
      }

      return acc
    }, {})

    const fields = Object.keys(fieldsOrdered)
      .sort()
      .map((key) => fieldsOrdered[key])

    // eslint-disable-next-line no-self-assign
    this.jsonValue = { ...this.jsonValue, fields }
  }

  jsonValueChanged() {
    this.#render()
  }

  #render() {
    const data = this.jsonValue
    let style = `--primary-color: ${data.primary_color}; --secondary-color: ${data.secondary_color};`
    style += ` --tertiary-color: ${data.tertiary_color}; --quaternary-color: ${data.quaternary_color};`
    style += ` --btn-bg: var(--primary-color); --btn-text-color: var(--quaternary-color);`
    style += ` --input-border-color: var(--secondary-color);`

    const content = html`
      <div
        class="bg-[--tertiary-color] p-3 pointer-events-none space-y-3"
        style="${style};  color: var(--secondary-color) !important;"
      >
        ${data.fields.map(
          (field) => html`
            <div>
              <label class="label !p-0 ${(field.key === 'name' || field.required) && 'required'}"
                >${field.name}${field.label.length > 0 ? field.label : 'Custom Field'}</label
              >
              ${this.#fieldHtml(field)}
            </div>
          `,
        )}

        <div class="flex justify-end">
          <button type="button" class="btn">SUBMIT</button>
        </div>
      </div>
    `

    render(content, this.containerTarget)
  }

  #fieldHtml(field) {
    if (field.type === 'tel') {
      return html`${unsafeHTML(this.phoneFieldTarget.innerHTML)}`
    }

    if (field.type === 'select') {
      return html`
        <select type="${field.type}" class="input input-border" readonly>
          <option selected disabled hidden>Select</option>
        </select>
      `
    }

    return html`<input type="${field.type}" class="input input-border" placeholder="Type here" readonly />`
  }
}
